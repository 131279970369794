:root {
  --primary: #ffffff;
  --primary-2: #f1f3f5;
  --secondary: #000000;
  --secondary-2: #111;
  --selection: var(--cyan);
  /* Font families */
  --header-font-family: "forma-djr-display", sans-serif;
  --small-font-family: "forma-djr-micro", sans-serif;
  --text-font-family: "forma-djr-text", sans-serif;

  /* New colours */
  --darkest-brown: #33251a;
  --dark-brown: #563e2c;
  --light-brown: #8f6d57;
  --lightest-brown: #E7DDD4;
  --whiteish: #faf6f2;
  --positive: #3d8036;
  --contrast: #cc4e00;
  --negative: #e0310d;

  /* Neutral tones */
  --neutral-50: #aa9e95;
  --neutral-40: #bbb2ab;
  --neutral-30: #ccc5c0;
  --neutral-20: #ddd8d5;
  --neutral-10: #eeecea;

  /* Demo colors */
  --demo-1: #788c89;
  --demo-2: #a16e5f;
  --demo-3: #fdf5de;
  --demo-4: #bf7f4f;

  /* Text colours */
  --text-color: var(--dark-brown);
  --header-color: var(--darkest-brown);

  --text-base: #000000;
  --text-primary: #000000;
  --text-secondary: white;

  --hover: rgba(0, 0, 0, 0.075);
  --hover-1: rgba(0, 0, 0, 0.15);
  --hover-2: rgba(0, 0, 0, 0.25);
  --cyan: #22b8cf;
  --green: #37b679;
  --red: #da3c3c;
  --purple: #f81ce5;
  --blue: #0070f3;

  --pink: #ff0080;
  --pink-light: #ff379c;

  --magenta: #eb367f;

  --violet: #7928ca;
  --violet-dark: #4c2889;

  --accent-0: #fff;
  --accent-1: #fafafa;
  --accent-2: #eaeaea;
  --accent-3: #999999;
  --accent-4: #888888;
  --accent-5: #666666;
  --accent-6: #444444;
  --accent-7: #333333;
  --accent-8: #111111;
  --accent-9: #000;

  --font-sans: -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue",
    "Helvetica", sans-serif;
}
body {
  margin: 0;
  font-family: var(--text-font-family), -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2 {
  color: var(--header-color) !important;
}

h3 {
  color: var(--light-brown) !important;
}

h2 {
  font-family: var(--header-font-family) !important;
  font-size: 38px !important;
  font-weight: 400 !important;
  line-height: 44px !important;
}
h3 {
  font-family: var(--header-font-family) !important;
  font-size: 20px !important;
  font-weight: normal !important;
  line-height: 22px !important;
}
h1 {
  font-family: var(--header-font-family);
  font-size: 2.1em;
  font-weight: 200;
}

p,
em,
div {
  font-family: var(--text-font-family);
  color: var(--text-color);
  margin: 0;
  line-height: 1.3em;
  letter-spacing: 0.03em;
}

input,
select {
  border: 2px solid var(--neutral-10);
  border-radius: 2px;
  color: var(--text-color);
  font-size: 18px;
  font-family: var(--text-font-family);
}
